const environment = (typeof window !== "undefined" && window._conf) || {
    ENV: "development",
    VALUE: "development",
    PORT: 9090,
    NAT_IP: "152.0.0.0",
    KAKASHI_MAIN_URL: "https://api.pixelbinz0.de/kakashi",
    APP_CDN_BASE_URL: "https://cdn-1.erasez0.de/",
    STORAGE_FILE_ERASEBG_CDN_ASSETS: "s3://erase-erasex0-erasebg-assets-ap-south-1/",
    APP_STRIPE_PUBLISHABLE_KEY:
        "pk_test_51KP0CaSIcn3d6hi2v1njD0FfcKmMPSeZjL7ZgJn9JD5hZ6TszcEtETRHiOFRTYSduWJ1ukKnXHHAK6vrvg33ydwz00SEpHKBZJ",
    APP_SIGNATURE_SECRET_KEY: "dda3acf671474662b8cdde080bd8cac5",
    RECAPTCHA_SITE_KEY: "6Ld-AAwdAAAAABndOF9Ci5gy1CvIgN78FOzKQnw4",
    RECAPTCHA_KEY: "6Ld-AAwdAAAAABndOF9Ci5gy1CvIgN78FOzKQnw4",
    GOOGLE_CLIENT_ID: "47660021793-1tq0rjl9bj2iups1mbhlee97p2p9pqn6.apps.googleusercontent.com",
    GOOGLE_FRONTEND_APP_ID:
        "47660021793-1tq0rjl9bj2iups1mbhlee97p2p9pqn6.apps.googleusercontent.com",
    SAKURA_MAIN_DOMAIN: "https://www.erasez0.de",
    SARADA_MAIN_DOMAIN: "https://www.upscalez0.de",
    SUIGETSU_MAIN_DOMAIN: "https://www.watermarkremoverz0.de",
    SAI_MAIN_DOMAIN: "https://www.shrinkz0.de",
    SATETSU_MAIN_DOMAIN: "https://www.convertfilesz0.de",
    MITSUKI_MAIN_DOMAIN: "https://www.pixelbinz0.de",
    NEJI_MAIN_URL: "https://api.pixelbinz0.de/service/panel/users",
    NEJI_WEBHOOK_URL: "https://api.pixelbinz0.de/service/webhook/users",
    HIRUZEN_MAIN_URL: "https://api.pixelbinz0.de/service/panel/organization",
    CHOJI_PNL_URL: "https://api.pixelbinz0.de/service/panel/assets",
    GAARA_MAIN_URL: "https://api.pixelbinz0.de/service/panel/notification",
    KAKASHI_PNL_URL: "https://api.pixelbinz0.de/service/panel/kakashi",
    ITACHI_PNL_URL: "https://api.pixelbinz0.de/service/panel/analytics",
    ERASEBG_STORAGE_S3_FOLDER: "uploads",
    SAMUI_MAIN_DOMAIN: "https://www.pixelbinz0.de/docs/",
    DEFAULT_PLAYGROUND_ASSET_URL:
        "https://cdn.pixelbinz0.de/dummy-cloudname/original/original/5c790681-bf5b-4c4f-a53b-69a4ca5c7c85.jpeg",
    JIRAIYA_PNL_URL: "https://api.pixelbinz0.de/service/panel/transformation",
    JIRAIYA_CDN_DOMAIN: "https://cdn.pixelbinz0.de",
    JIRAIYA_WEBHOOK_URL: "https://api.pixelbinz0.de/service/webhook/transformation",
    HINATA_MAIN_DOMAIN: "https://local.pixelbinz0.de:9090",
    RUPIKA_PNL_URL: "https://api.pixelbinz0.de/service/panel/payment",
    SHIRAYUKI_PNL_URL: "https://api.pixelbinz0.de/service/panel/auditLogs",
    GTM_CONTAINER_ID: "GTM-NQ2K689",
    API_MAIN_DOMAIN: "https://api.pixelbinz0.de",
    BOLTIC_MAIN_DOMAIN: "boltic.io",
    BOLTIC_AUTH_URL: "https://connect.boltic.io/",
    newrelic_app: "",
    newrelic_license_key: "",
    INR_CONVERSION_RATE: 81,
    GST_RATE: 18,
    CGST_RATE: 9,
    SGST_RATE: 9,
    FREE_PLAN_ID: -994,
    CLOUDFLARE_CUSTOM_ORIGIN: "cdn-1.pixelbinz0.de",
    CLOUDFLARE_ZONE_ID: "cfc4ca53acf8f1b8f7b9c6a69d1bc965",
    maxSignedUrlExpiry: 21600,
    GLAMAR_CONSOLE_DOMAIN: "https://console.glamarz0.de",
    KIBA_MAIN_DOMAIN: "https://glamarz0.de",
    HIDAN_PNL_URL: "https://api.pixelbinz0.de/service/panel/misc",
    GLAMAR_SDK_SCRIPT_URL: "https://sdk.glamarz0.de/glamar-sdk.umd.js",
    FYNDSTYLE_SDK_SCRIPT_URL: "https://fyndstylesdk.glamarz0.de/fyndstyle-sdk.umd.js",
    APPLE_OAUTH_DEFAULT_NAME: "Anon-5fb8c939",
    BORUTO_PUBLIC_URL: "https://api.pixelbinz0.de/cms",
    PADDLE_USERS: "",
    OPTIONAL_PADDLE: false,
    PADDLE_CLIENT_SIDE_TOKEN: "test_8b6d6c0f76f361c5b332f9ef8d2",
    CREDIT_MAPPING: '{"VirtualTryOn": 0.4}',
    PIXELBIN_CDN_DOMAINS: ["cdn.pixelbinz0.de", "cdn-ak.pixelbinz0.de"],
    DROPBOX_AUTH_DOMAINS: ["dropbox.com", "www.dropbox.com"],
    SKIP_ONE_TAP_ANON_ID_WAIT: false,
    FIRSTPROMOTOR_ACCOUNT_ID: "",
    GLAMAR_API_KEY: "ff4146c9-386a-463d-9b7d-4191bfa35c7f",
    FREE_PROPERTY_PLAN_NAME: "free_property_monthly",
    ONBOARDING_PLAN_NAME: "onboarding_monthly",
    SPECIAL_LAUNCH_PLAN_NAME: "special_launch_monthly",
};

export default environment;
